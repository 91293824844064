import React, { useCallback, useEffect, useRef, useState } from 'react';
import type { FC } from 'react';
import styled from 'styled-components';
import { Seo, Text } from 'components';
import ArrowDownIcon from 'assets/icons/arrow-drop-down-down.svg';
import ArrowRightIcon from 'assets/icons/arrow-drop-down-right.svg';
import { tablet, useQuery } from 'styles/breakpoints';

import Layout from 'layouts/Layout';

interface Item {
  question: string;
  answer: string[];
}

interface PeopleAskUsProps {
  title: string;
  list: Item[];
}

const Container = styled.div`
  margin: auto;
  max-width: 100%;
  padding: 0 32px;
  width: 1204px;
  display: flex;
  flex-direction: column;
  gap: 3rem;
  align-items: center;
  @media ${tablet} {
    gap: 2rem;
    padding: 0;
  }
`;

const QuestionContainer = styled.div<{ expanded: boolean }>`
  overflow: ${({ expanded }) => (expanded ? 'auto' : 'hidden')};
  ::-webkit-scrollbar {
    width: 0;
  }
  transition: max-height 0.6s ease;
  border-radius: 1rem;
  background-color: ${({ theme }) => theme.colors.light0};
  cursor: pointer;
  padding: 1.5rem 0;
`;

const TitleContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  padding-bottom: 8px;
  border-bottom: 1px solid #e4e4eb;
  justify-content: space-between;
`;

const Question = styled(Text).attrs({
  type: 'tabActive',
  color: 'dark80',
})`
  font-size: 1.125rem;
  transition: color 0.2s ease;
  font-family: Inter;
  font-weight: 600;
  &:hover {
    color: ${({ theme }) => theme.colors.dark60};
  }
  /* fixes large text on ios issue */
  -webkit-text-size-adjust: none;
`;

const List = styled.div`
  max-width: 100%;
  width: 832px;
  div:nth-child(1n):not(:last-child) {
    margin-bottom: 1.5rem;
  }
`;

const AnswerContainer = styled.div<{ expanded: boolean }>`
  max-height: ${({ expanded }) => (expanded ? 700 : 0)}px;
  transition: max-height 0.4s ease;
  margin-top: 1.06rem;
  /* hide scrollbar for IE, Edge and Firefox */
  -ms-overflow-style: none;
  scrollbar-width: none;
  overflow-y: auto;
  /* hide scrollbar for Chrom, Safari */
  ::-webkit-scrollbar {
    width: 0;
    display: none;
  }
`;

const ArrowDown = styled(ArrowDownIcon)`
  max-width: 1.5rem;
  min-width: 1.5rem;
`;

const ArrowUp = styled(ArrowRightIcon)`
  max-width: 1.5rem;
  min-width: 1.5rem;
  rotate: 270deg;
`;

const Answer = styled(Text).attrs({
  type: 'bodyM',
})`
  padding-bottom: 1.5rem;
  font-family: Inter;
  -webkit-text-size-adjust: 100%;
`;

const FAQ: FC<PeopleAskUsProps> = () => {
  const [expandedQuestions, setExpandedQuestions] = useState<number[]>([]);
  const childRef = useRef<HTMLDivElement>(null);

  const { isTablet } = useQuery();

  const handleClick = useCallback(
    (index: number) => {
      if (expandedQuestions.includes(index)) {
        setExpandedQuestions(prevState => [
          ...prevState.filter(item => item !== index),
        ]);
        return;
      }
      setExpandedQuestions(prevState => [...prevState, index]);
    },
    [expandedQuestions],
  );

  useEffect(() => {
    if (!childRef.current) return;
  }, [childRef, expandedQuestions]);

  const isExpanded = (index: number) => expandedQuestions.includes(index);

  return (
    <Layout
      seoTitle="FAQ"
      seoDescription="Find out more about Mindway App"
      seoName="FAQ"
    >
      <Wrapper>
        <Container>
          <Text type={isTablet ? 'h2600' : 'h125Rem'} textAlign="center">
            Frequently asked questions
          </Text>
          <List>
            <QuestionContainer expanded={isExpanded(1)}>
              <TitleContainer onClick={() => handleClick(1)}>
                <Question>How does Mindway work?</Question>
                {isExpanded(1) ? <ArrowUp /> : <ArrowDown />}
              </TitleContainer>

              <AnswerContainer expanded={isExpanded(1)}>
                <div ref={childRef}>
                  <Answer>
                    The Mindway plan is a step-by-step guide to help you take
                    control of overthinking. Mindway is based on mindfulness and
                    cognitive-behavioral techniques proven to reduce
                    overthinking tendencies.
                  </Answer>
                  <Answer>
                    With Mindway, you will analyze your overthinking triggers,
                    thought patterns, emotions, habits, and many other areas of
                    your mental and emotional health. You will get data-based
                    personal insights on what alleviates your overthinking and
                    build the ultimate daily routine to regain clarity and peace
                    of mind.
                  </Answer>
                  <Answer>
                    By experimenting with your habits and following your plan,
                    you will continuously make simple changes that lead to a
                    more focused, stress-free, and fulfilling life.
                  </Answer>
                </div>
              </AnswerContainer>
            </QuestionContainer>
            <QuestionContainer expanded={isExpanded(2)}>
              <TitleContainer onClick={() => handleClick(2)}>
                <Question>Is Mindway right for me?</Question>
                {isExpanded(2) ? <ArrowUp /> : <ArrowDown />}
              </TitleContainer>

              <AnswerContainer expanded={isExpanded(2)}>
                <div ref={childRef}>
                  <Answer>
                    If you are seeking answers to these questions, Mindway is a
                    perfect fit:
                  </Answer>
                  <Answer>
                    How can I quiet my racing thoughts?
                    <br />
                    How do I stay present and stop dwelling on the past or
                    worrying about the future?
                    <br />
                    Why do I struggle to make decisions without overanalyzing?
                    <br />
                    How can I reduce stress and anxiety caused by overthinking?
                    <br />
                    How can I break free from the cycle of endless thoughts?
                    <br />
                    How do I regain confidence and trust in my decision-making
                    abilities?
                    <br />
                    How can I enjoy the present moment without constant mental
                    clutter?
                    <br />
                    How do I achieve a calm and focused mind?
                    <br />
                    We are here to remove any barriers preventing you from
                    living a present, balanced, and confident life.
                  </Answer>
                </div>
              </AnswerContainer>
            </QuestionContainer>
            <QuestionContainer expanded={isExpanded(3)}>
              <TitleContainer onClick={() => handleClick(3)}>
                <Question>
                  When can I expect to see improvements in my life with Mindway?
                </Question>
                {isExpanded(3) ? <ArrowUp /> : <ArrowDown />}
              </TitleContainer>

              <AnswerContainer expanded={isExpanded(3)}>
                <div ref={childRef}>
                  <Answer>
                    As soon as you start your personalized program, you will
                    immediately begin implementing small changes that lead to
                    significant improvements in your mental clarity and overall
                    well-being.
                  </Answer>
                  <Answer>
                    87% of our users report experiencing reduced overthinking,
                    increased mental clarity, and improved emotional well-being
                    within the first 2 weeks of using Mindway.
                  </Answer>
                </div>
              </AnswerContainer>
            </QuestionContainer>
            <QuestionContainer expanded={isExpanded(4)}>
              <TitleContainer onClick={() => handleClick(4)}>
                <Question>
                  How will Mindway help me overcome overthinking and improve my
                  life?
                </Question>
                {isExpanded(4) ? <ArrowUp /> : <ArrowDown />}
              </TitleContainer>

              <AnswerContainer expanded={isExpanded(4)}>
                <div ref={childRef}>
                  <Answer>
                    You will receive step-by-step instructions on how to build
                    your ultimate routine. Your plan will be broken down into
                    smaller sections with easy-to-implement tasks, lessons, and
                    practices. Each section focuses on enhancing different
                    aspects of your life affected by overthinking:
                  </Answer>
                  <Answer>
                    <br />
                    Quieting racing thoughts and mental clutter
                    <br />
                    Cultivating mindfulness and present-moment awareness
                    <br />
                    Regaining confidence in decision-making
                    <br />
                    Reducing stress and anxiety
                    <br />
                    Breaking free from the cycle of endless thoughts
                    <br />
                    Achieving emotional balance and clarity
                    <br />
                    Fostering a calm and focused mind
                    <br />
                    There is no one-size-fits-all solution to overcoming
                    overthinking - that&apos;s why our personalized approach is
                    the most effective way to achieve your goals. You will track
                    your progress at every step and receive personalized
                    insights to regain control over your thoughts.
                  </Answer>
                </div>
              </AnswerContainer>
            </QuestionContainer>
            <QuestionContainer expanded={isExpanded(5)}>
              <TitleContainer onClick={() => handleClick(5)}>
                <Question>Is Mindway backed by science?</Question>
                {isExpanded(5) ? <ArrowUp /> : <ArrowDown />}
              </TitleContainer>

              <AnswerContainer expanded={isExpanded(5)}>
                <div ref={childRef}>
                  <Answer>
                    Mindway personalized plans are developed by a team of mental
                    health experts and psychologists to ensure they have a
                    significant impact on reducing overthinking tendencies and
                    improving mental well-being.
                  </Answer>
                </div>
              </AnswerContainer>
            </QuestionContainer>
            <QuestionContainer expanded={isExpanded(6)}>
              <TitleContainer onClick={() => handleClick(6)}>
                <Question>How does Mindway protect my data?</Question>
                {isExpanded(6) ? <ArrowUp /> : <ArrowDown />}
              </TitleContainer>
              <AnswerContainer expanded={isExpanded(6)}>
                <div ref={childRef}>
                  <Answer>
                    All your data is encrypted and remains confidential. You
                    have complete control over your data and can delete it at
                    any time.
                  </Answer>
                  <Answer>
                    Data privacy is a top priority, and we will always
                    prioritize helping you make healthy decisions while
                    respecting your privacy.
                  </Answer>
                </div>
              </AnswerContainer>
            </QuestionContainer>
          </List>
        </Container>
      </Wrapper>
    </Layout>
  );
};

export default React.memo(FAQ);

const UnderscoreLink = styled.a`
  text-decoration: underline !important;
`;

const Wrapper = styled.div`
  flex-grow: 1;
  overflow: hidden;
  padding: 3rem 1rem;
  position: relative;
  min-height: calc(100vh - 416px);
  height: 100%;
  @media ${tablet} {
    z-index: 0;
    padding: 1.5rem 1rem 3rem 1rem;
  }
`;
